<template>
  <div class="sceneryLog">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>日志列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>所属分类:</span>
      <el-select v-model="list.logType" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="getsceneryLogList()">查找</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-table :data="tableData" border>
      <el-table-column
        align="center"
        prop="hotelLogId"
        label="日志ID"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="strType"
        label="修稿内容"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="masterName"
        label="修改人"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="hotelAddTime"
        label="修改时间"
      ></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            @click="$router.push(`/sceneryLogDetails/${row.hotelLogId}`)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { sceneryLogList } from "../../api/sceneryLog";
export default {
  name: "sceneryLog",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      tableData: [],
      options: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 1,
          label: "新增",
        },
        {
          value: 2,
          label: "上下架",
        },
        {
          value: 3,
          label: "景区名称",
        },
        {
          value: 4,
          label: "所属分类",
        },
        {
          value: 5,
          label: "所在街道",
        },
        {
          value: 6,
          label: "详细地址",
        },
        {
          value: 7,
          label: "纬度",
        },
        {
          value: 8,
          label: "经度",
        },
        {
          value: 9,
          label: "展示图",
        },
        {
          value: 10,
          label: "轮播图",
        },
        {
          value: 11,
          label: "营业时间",
        },
        {
          value: 12,
          label: "排序",
        },
        {
          value: 13,
          label: "标签",
        },
        {
          value: 14,
          label: "简介",
        },
        {
          value: 15,
          label: "评分",
        },
        {
          value: 16,
          label: "客服电话",
        },
        {
          value: 17,
          label: "预定须知",
        },
        {
          value: 18,
          label: "详情",
        },
      ],
      pagination: {},
      list: {
        sceneryId: this.id,
        currentPage: 1,
        pageSize: 5,
        logType: null,
      },
    };
  },
  created() {
    this.getsceneryLogList();
  },
  methods: {
    async getsceneryLogList() {
      const { data } = await sceneryLogList(this.list);
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getsceneryLogList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getsceneryLogList();
    },
  },
};
</script>
<style lang="less" scoped>
.sceneryLog {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    span {
      margin-right: 20px;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>